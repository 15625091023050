<template>
    <Success :data="data"/>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import Success from '../../components/Success/index'
export default {
  components: { Success },
  setup () {
    const state = reactive({
      data: {
        title: '小盈正在快马加鞭完成审核，大约10分钟给出评估结果，请耐心等待',
        btnTit: '完成'
      }

    })
    return { ...toRefs(state) }
  }
}
</script>

<style>

</style>
